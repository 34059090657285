<template>
  <div class="app-container">
    <CrudTable entity="ExternalApiUser" :columns="columns" :generate-summary="generateSummary" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [`username`]
    };
  },

  methods: {
    generateSummary({ username, domainName }) {
      const name = domainName;
      return `${username}, ${name}`;
    }
  }
};
</script>
